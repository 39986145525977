.overflow_scroll {
  overflow-y: scroll!important;
}

.overflow_scroll body {
  padding-right: 0!important;
}

input.input-phone {
  border: 1px solid #D1D5DB;
  border-radius: 0.5rem;
  padding: 0.5rem 16px!important;
  line-height: 1.5755em
}

input.input-phone:focus {
  border-bottom: 2px solid #CC0000;
  outline: none!important;
}

.swal2-container {
  z-index: 10000!important;
}

.swal2-popup.swal2-modal {
  background: #FBF3F3;
  border-radius: 10px;
}

button.swal2-confirm {
  line-height: 1.4375em;
  font-size: 0.9rem;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

button.swal2-confirm:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #D71F27;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

#swal2-title {
  color: #000606;
  padding: 0em 1em 0;
}

div:where(.swal2-icon).swal2-error {
  border-color: #CC0000;
  color: #CC0000;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
  background-color: #CC0000;
}

div:where(.swal2-icon) {
  margin: 1.5em auto .6em!important;
}

div:where(.swal2-icon) {
  width: 3em!important;
  height: 3em!important;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
  top: 1.3535em!important;
  width: 1.9375em!important;
  height: .3125em;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 0.5225em!important;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 0.545em!important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.5535em!important;
  left: .6925em!important;
  width: 0.8375em!important;
  height: .3125em;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=long] {
  right: .45em!important;
  top: 1.375em!important;
  width: 1.5375em!important;
}

div:where(.swal2-icon).swal2-success .swal2-success-fix {
  top: .25em;
  left: 0.625em;
  width: .4375em;
  height: 1.625em;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line][class$=left],
div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line][class$=right],
div:where(.swal2-icon).swal2-success .swal2-success-fix,
div:where(.swal2-icon).swal2-success .swal2-success-ring  {
  opacity: 0!important;
}

.canvasPDF {
  height: 50vh;
  overflow-y: scroll;
}