@font-face {
  font-family: "Inter", sans-serif;
  src: url(./fonts/Inter-Thin.ttf) format('truetype');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Inter", sans-serif;
  src: url(./fonts/Inter-ExtraLight.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Inter", sans-serif;
  src: url(./fonts/Inter-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Inter", sans-serif;
  src: url(./fonts/Inter-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Inter", sans-serif;
  src: url(./fonts/Inter-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Inter", sans-serif;
  src: url(./fonts/Inter-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Inter", sans-serif;
  src: url(./fonts/Inter-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Inter", sans-serif;
  src: url(./fonts/Inter-ExtraBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 800;
}

/***
Poppins
************/

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(./fonts/Poppins-Thin.ttf) format('truetype');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url(./fonts/Poppins-ExtraLight.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url(./fonts/Poppins-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url(./fonts/Poppins-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url(./fonts/Poppins-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url(./fonts/Poppins-ExtraBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 800;
}
